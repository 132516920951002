
.pdp-sticky-cta-bar {
    position: fixed;
    bottom: 0;
    z-index: 10

    width: 100vw;

    background-color: $color-dapp-gray-4;
    box-shadow: inset 0 .03125rem 0 0 #d2d2d7
}
.pdp-sticky-cta-container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 16px;

    @media screen and (min-width: 768px) and (max-width: 1252px) {
        padding: 16px;
    }

    @media screen and (min-width: 1024px) {
        padding: 20px 12px;
    }

}

.pdp-sticky-cta {
    width: 100%;
    max-width: 100%;
    justify-content: center;
    align-items: center;

    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(36px, auto);

    @media screen and (min-width: 768px) {
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(3, 2fr);
        grid-auto-rows: minmax(50px, auto);
    }
}

.btn-link {
    padding 0 !important
    color: $color-bnn;
    cursor pointer

    &:hover:before {
        background: transparent !important
    }

    .v-icon {
        color: $color-bnn
    }
}

.payment-learn-more {
    grid-column: 1 / span 2;
    text-align: right;
}
